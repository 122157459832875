import React, { useState } from "react";
import { Image } from "../../utils/helper";
import "../BlogDetail/blogdetails.scss";
import { DialogOverlay } from "@reach/dialog";
import "@reach/dialog/styles.css";
import closeIcon from "../../images/close-svgrepo-com.svg";

const ImageModals = ({ Images }) => {
  const [dialog, SetDialog] = useState(null);
  const [imageIndex, setImageIndex] = useState(0);

  return dialog === Images[imageIndex]?.image?.filename ? (
    <DialogOverlay
      isOpen={dialog === Images[imageIndex]?.image?.filename}
      onDismiss={() => SetDialog(null)}
      className="overlay-image"
    >
      {/* <Image src={props?.src} alt={props?.alt}/> */}
      <Image
        src={Images[imageIndex]?.image?.filename}
        alt={Images[imageIndex]?.image?.alt}
      />
      <img src={closeIcon} className="closeicon" />
    </DialogOverlay>
  ) : (
    <div
      className="tabImageSection"
      onClick={() => SetDialog(Images[imageIndex]?.image?.filename)}
    >
      <Image
        src={Images[imageIndex]?.image?.filename}
        alt={Images[imageIndex]?.image?.alt}
      />
      <div className="altText">{Images[imageIndex]?.image?.alt}{console.log(Images[imageIndex], "[CURRENT]")}</div>
      <div className="backSlashWrap">
        {Images?.map((i, index) => (<>
          <div
            key={index}
            className={index == imageIndex && "active"}
            onClick={() => setImageIndex(index)}
          >
            {i?.image?.title}
          </div>
          {/* {index != Images.length - 1 ? "" : ""} */}
          </>
        ))}
      </div>
    </div>
  );
};

export default ImageModals;
