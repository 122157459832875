import React from "react";
import { Wistia } from "mdx-embed";

const WistiaPlayer = ({ blok }) => {
  const { videoId, allowFullScreen, aspectRatio, autoPlay, height, width } =
    blok || {};
    return (
    <div>
      <Wistia
        videoId={videoId}
        allowFullScreen={allowFullScreen}
        aspectRatio={aspectRatio}
        autoPlay={autoPlay}
        height={height}
        width={width}
      />
    </div>
  );
};

export default WistiaPlayer;
