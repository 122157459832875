// src/components/CodeDemo.js
import clipboardCopy from 'clipboard-copy';
import Highlight, { defaultProps } from 'prism-react-renderer';
import React, { useState } from 'react';
import ReactSyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import './codedemo.scss';

export function CodeDemo({ code, children }) {

  const [raw, setRaw] = useState(false)
  return (
    <div className="codeWrap" onDoubleClick={() => setRaw(!raw)}>
      <div className='code-bg-section'>
          <div className='code-header'>
            <button onClick={() => { setRaw(!raw) }}>Raw</button>
            <button onClick={() => { clipboardCopy(code) }}>Copy</button>
          </div>
          <ReactSyntaxHighlighter style={a11yLight} >
            {code}
          </ReactSyntaxHighlighter>
      </div>
      {/* )}
      </Highlight> */}
    </div>
  );
}




