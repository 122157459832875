import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import date from "date-and-time";
import ru from "date-and-time/locale/ru";
import en from "date-and-time/locale/en";
import { DialogOverlay } from "@reach/dialog";
import { CodeDemo } from "../components/CodeDemo";
import {
  MARK_LINK,
  NODE_CODEBLOCK,
  NODE_HEADING,
  NODE_IMAGE,
  render,
  NODE_QUOTE,
} from "storyblok-rich-text-react-renderer";
import { StoryblokComponent } from "gatsby-source-storyblok";
import closeIcon from "../images/close-svgrepo-com.svg";
import WistiaPlayer from "../components/WistiaPlayer";
import ImageModals from "../components/ImageModel";

/** checks for any parsing errors **/
export const isJsonParsable = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (err) {
    return false;
  }
};
/** returns the location path according to language **/
export const getPathName = ({ code, pathname }) => {
  switch (code) {
    case "ru":
      if (pathname?.startsWith("/ru")) {
        return pathname;
      } else {
        return "/ru" + pathname;
      }
    default:
      if (pathname?.startsWith("/ru")) {
        return "/" + pathname.split("/").slice(2)?.join("/");
      } else {
        return pathname;
      }
  }
};

/** special function to find out subitems for navigation menu **/
export const getSubitems = (data) => {
  const keys = ["Sub_Services", "Sub_Solutions"];
  let result = {};
  keys.forEach((key) => {
    result[key] = data[key];
  });
  return result;
};

/** returns a valid link **/
export const getLink = (url) => {
  if (url?.length === 0) {
    return "/";
  }
  const url_pre = url?.startsWith("/") ? url : "/" + url;
  const url_final =
    url_pre[url_pre.length - 1] == "/" ? url_pre : url_pre + "/";
  return url_final;
};

/** returns true if any subnav_item is active **/
export const isSubItemActive = (sub_items, location) => {
  return sub_items?.find((data) => {
    if (isJsonParsable(data?.node?.content)) {
      var found = true;
      getLink(JSON.parse(data?.node?.content)?.Link?.cached_url)
        ?.split("/")
        ?.map((item) => {
          if (!location?.pathname?.split("/")?.includes(item)) {
            found = false;
          }
          return item;
        });
    }
    return found;
  })
    ? true
    : false;
};

/** returns true if any subnav_item has images for preview **/
export const hasImages = (sub_items) => {
  return (
    sub_items?.filter((data) => {
      if (isJsonParsable(data?.node?.content)) {
        return (
          JSON.parse(data?.node?.content)?.Image?.filename?.trim()?.length > 0
        );
      }
      return false;
    })?.length > 0
  );
};

export const useImage = ({ url }) => {
  const { data } = useStaticQuery(graphql`
    query MyQuery {
      data: allFile {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(formats: WEBP, width: 1000, quality: 100)
            }
            url
          }
        }
      }
    }
  `);
  const image =
    data?.edges?.find((i) => i?.node?.url === url)?.node?.childImageSharp
      ?.gatsbyImageData || null;
  return image;
};

export const Image = ({ src, alt, ...props }) => {
  const image = useImage({ url: src });
  return image ? (
    <GatsbyImage image={image} {...props} alt={alt ?? ""} />
  ) : (
    <img src={src} {...props} alt={alt ?? ""} />
  );
};

export function partial_match(x, y) {
  if (typeof x !== "string" || typeof y !== "string") {
    return x === y;
  }
  return x.trim().toLowerCase().startsWith(y.trim().toLowerCase());
}

export const Table = ({ head, body, collapsible }) => {
  return (
    <table>
      <tbody>
        <tr>
          {head?.map((hd, idx) => (
            <th key={idx}> {hd?.value} </th>
          ))}
        </tr>
        {body?.map((bd, idx) => {
          return (
            <tr key={idx}>
              {bd?.body?.map((item, idx) => (
                <td key={idx}> {item?.value} </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
/** returns current language **/
export function getActiveLanguage(location) {
  let { pathname } = location || {};
  let language = pathname?.split("/")[1].replace("/", "");
  let activeLanguage = ["ru"].includes(language) ? language : "default";
  return activeLanguage;
}

/** converts json string to object **/
export function parse(str) {
  return isJsonParsable(str) ? JSON.parse(str) : null;
}

/** converts date string to locale string **/
export function getDate(time, locale = "en") {
  let locales = {
    ru: ru,
  };
  date.locale(locales[locale] ?? en);
  return date.format(new Date(time), "MMMM D, YYYY");
}

export const getHeadingId = (children) => {
  if (typeof children === "string" && children.length > 0) {
    return children?.replace(/\s|\?/g, "_");
  } else if (Array.isArray(children)) {
    var id = children
      .map((child) => {
        if (typeof child === "string" && child.length > 0) {
          return child.replace(/\s|\?/g, "_");
        }
        return getHeadingId(child);
      })
      .filter((i) => i)
      .join("_");
    return id;
  } else if (children?.props) {
    return getHeadingId(children?.props?.children);
  } else {
    return;
  }
};

export function RichText({ content }) {
  const [dialog, SetDialog] = useState();
  if (typeof content === "string") {
    return <p>{content}</p>;
  }
  return render(content, {
    markResolvers: {
      [MARK_LINK]: (children, props) => {
        const { linktype, href, target } = props;
        if (linktype === "email") {
          // Email links: add `mailto:` scheme and map to <a>
          return <a href={`mailto:${href}`}>{children}</a>;
        }
        if (href.match(/^(https?:)?\/\//)) {
          // External links: map to <a>
          return (
            <a href={href} target={target}>
              {children}
            </a>
          );
        }
        // Internal links: map to <Link>
        return <Link to={getLink(href)}>{children}</Link>;
      },
    },
    blokResolvers: {
      ["List_Item"]: (props) => {
        const { Tag_Name } = props;
        return (
          <a href={"#" + Tag_Name?.replace(/ /g, "_")}>
            <span> {Tag_Name} </span>
            <span> Read </span>
          </a>
        );
      },
      ["Two_Column_Table"]: (props) => {
        const { tbody: body, thead: head } = props?.Table;
        return (
          <div>
            <Table head={head} body={body} />
          </div>
        );
      },
      ["Blog Navigation"]: (props) => {
        const { title, tag_items } = props;
        return (
          <div className="blog-navigatiion">
            <p> {title} </p>
            <ol>
              {tag_items?.map((item, idx) => (
                <li key={idx}>
                  {" "}
                  <Link to={"#" + item?.Tag_Name?.replace(/\s|\?/g, "_")}>
                    {item?.Tag_Name}
                  </Link>
                </li>
              ))}
            </ol>
          </div>
        );
      },
      ["VideoEmbed"]: (props) => {
        return <div dangerouslySetInnerHTML={{ __html: props.source }} />;
      },
      ["wistia_player"]: (props) => {
        return <WistiaPlayer blok={props} key={props._uid} />;
      },
      ["Images"]: (props) => {
        const allImages = props?.image_items;
        return <ImageModals Images={allImages} />
      },
    },
    nodeResolvers: {
      [NODE_CODEBLOCK]: (children) => {
        if (Array.isArray(children)) {
          let code = children.join("");
          return <CodeDemo code={code} />;
        }
        return <CodeDemo code={children} />;
      },
      [NODE_HEADING]: (children, props) => {
        switch (props?.level) {
          case 1:
            return <h1 id={getHeadingId(children)}>{children}</h1>;
          case 2:
            return <h2 id={getHeadingId(children)}>{children}</h2>;
          case 3:
            return <h3 id={getHeadingId(children)}>{children}</h3>;
          case 4:
            return <h4 id={getHeadingId(children)}>{children}</h4>;
          case 5:
            return <h5 id={getHeadingId(children)}>{children}</h5>;
          case 6:
            return <h6 id={getHeadingId(children)}>{children}</h6>;
          default:
            return;
        }
      },
      [NODE_IMAGE]: (children, props) => {
        // console.log('props', props)
        return dialog === props?.src ? (
          <DialogOverlay
            isOpen={dialog === props?.src}
            onDismiss={() => SetDialog(null)}
            className="overlay-image"
          >
            <Image src={props?.src} alt={props?.alt} />
            <img src={closeIcon} className="closeicon" />
          </DialogOverlay>
        ) : (
          <div onClick={() => SetDialog(props?.src)}>
            <Image src={props?.src} alt={props.alt} />
          </div>
        );
      },
    },
    defaultBlokResolver: (name, props) => {
      const blok = {
        ...props,
        component: name,
      };

      return <StoryblokComponent blok={blok} key={props._uid} />;
    },
  });
}
