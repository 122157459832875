import React from "react";
import { getActiveLanguage } from "../../utils/helper";

export const default_state = {
  data: {
    lang: "default",
    location: { pathname: "" },
    intercom_loaded: false,
    headerMode: "default",
  },
  methods: {
    setLang: () => {},
    setLocation: () => {},
    loadIntercom: () => {},
    setHeaderMode: () => {},
  },
};

export const GlobalContext = React.createContext(default_state);
const GlobalProvider = ({ children }) => {
  const [state, setState] = React.useState(default_state);
  const setLang = (val) => {
    setState((s) => {
      return {
        ...s,
        data: {
          ...s.data,
          lang: val,
        },
      };
    });
  };
  const setLocation = (val) => {
    setState((s) => {
      return {
        ...s,
        data: {
          ...s.data,
          lang: getActiveLanguage(val),
          location: val,
        },
      };
    });
  };
  const loadIntercom = () => {
    if (state.data.intercom_loaded) {
      return;
    }
    const primary_script = document.createElement("script");
    const snippet = `
            window.intercomSettings = {
              app_id: "c5hmkfsy",
              custom_launcher_selector:'#my_custom_link'
            };
            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/c5hmkfsy';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
          `;
    primary_script.append(snippet);
    document.head.appendChild(primary_script);
    const load_script = document.createElement("script");
    const snippet_load = `window.Intercom("boot", { api_base: "https://api-iam.intercom.io", app_id: "c5hmkfsy" });`;
    load_script.append(snippet_load);
    document.head.appendChild(load_script);
    setState((s) => {
      return {
        ...s,
        data: {
          ...s.data,
          intercom_loaded: true,
        },
      };
    });
  };

  const setHeaderMode = (val) => {
    setState((s) => {
      return {
        ...s,
        data: {
          ...s.data,
          headerMode: val ?? s.data.headerMode,
        },
      };
    });
  };
  const data = state.data;
  const methods = {
    setLang: setLang,
    setLocation: setLocation,
    loadIntercom: loadIntercom,
    setHeaderMode:setHeaderMode
  };
  const value = {
    data,
    methods,
  };
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export { GlobalProvider };
