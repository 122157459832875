exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-detail-js": () => import("./../../../src/templates/blog-detail.js" /* webpackChunkName: "component---src-templates-blog-detail-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-demo-template-js": () => import("./../../../src/templates/demo-template.js" /* webpackChunkName: "component---src-templates-demo-template-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-pricing-template-js": () => import("./../../../src/templates/pricing-template.js" /* webpackChunkName: "component---src-templates-pricing-template-js" */),
  "component---src-templates-product-detail-template-js": () => import("./../../../src/templates/product-detail-template.js" /* webpackChunkName: "component---src-templates-product-detail-template-js" */),
  "component---src-templates-sidebar-template-js": () => import("./../../../src/templates/sidebar-template.js" /* webpackChunkName: "component---src-templates-sidebar-template-js" */),
  "component---src-templates-sources-template-js": () => import("./../../../src/templates/sources-template.js" /* webpackChunkName: "component---src-templates-sources-template-js" */),
  "component---src-templates-thanks-template-js": () => import("./../../../src/templates/thanks-template.js" /* webpackChunkName: "component---src-templates-thanks-template-js" */)
}

